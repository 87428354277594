import { computed } from 'vue'
import { useUser }  from '@/components/use/useUser'

export const usePermissions = props => {
  const { user } = useUser(props)
  const canRead = computed(() => !!user.value)
  const canEdit = computed(() => !!user.value)
  const canManage = computed(() => !!user.value)
  const canOwn = computed(() => !!user.value)
  return {
    canRead,
    canEdit,
    canManage,
    canOwn
  }
}
