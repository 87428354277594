import { computed }                 from 'vue'
import { capitalize, decapitalize } from '../../../../lib/stringFunctions'

export const useAssetType = props => {
  const assetAlias = computed(() => decapitalize(props.assetType))
  const assetAliasPlural = computed( () => {
    let plural
    if (props.assetPluralName) {
      plural = decapitalize(props.assetPluralName)
    } else {
      plural = assetAlias.value  + 's'
    }
    return plural
  })
  return {
    assetAlias,
    assetAliasPlural,
    assetTypePlural: computed(() => props.assetPluralName ?? capitalize(assetAliasPlural.value)),
  }
}
