import { ref, watch } from 'vue'
import { validate }   from '@/components/utilities/validation'

export const useUrlSafeInput = props => {
  const isConfirmed = ref(props.isConfirmed)
  const newUrlSafeString = ref('')
  const conflictingChars = ref('')
  const validationErrorMessage = ref('')
  watch(isConfirmed, (oldValue, newValue) => {
    const confirmedNow = oldValue === false && newValue
    if (confirmedNow) {
      reset()
    }
  })
  const assertIsUrlSafe = string => {
    return validate(string, 'isUrlSafe')
  }
  const getErrorMessage = () => {
    const isPlural = conflictingChars.value.length === 1
    return `unsafe character${ isPlural ? 's' : '' } found`
  }
  const listUrlUnsafeCharacters = string => string.split('').filter(char => encodeURIComponent(char).length > 1)
  const reset = () => {
    resetError()
    newUrlSafeString.value = ''
  }
  const resetError = () => {
    validationErrorMessage.value = ''
    conflictingChars.value = ''
  }
  const validateString = (newValue) => {
    if (props.escape) {
      newUrlSafeString.value = encodeURIComponent(newValue.replaceAll(/ /g, '_'))
      resetError()
    } else {
      newUrlSafeString.value = newValue.replaceAll(/ /g, '_')
      const stringIsUrlSafe = assertIsUrlSafe(newUrlSafeString.value) || !newValue.length
      if (!stringIsUrlSafe) {
        conflictingChars.value = listUrlUnsafeCharacters(newUrlSafeString.value).join('')
        validationErrorMessage.value = getErrorMessage()
      } else {
        resetError()
      }
    }
  }
  return {
    conflictingChars,
    newUrlSafeString,
    validationErrorMessage,
    validateString,
  }
}
