import { computed, ref } from 'vue'

export default function useTimer() {
  let timeoutId
  let onDone
  let seconds
  let countdown = ref(-1)

  const setTimer = (duration, onTimerFinished) => {
    onDone = onTimerFinished
    seconds = duration
    countdown.value = duration
    getTimeout(1000)
  }
  const tick = () => {
    if (countdown.value > 0) {
      countdown.value--
      getTimeout(1000)
    } else onDone
  }
  const cancelTimeout = () => clearTimeout(timeoutId)
  const getTimeout = (duration) => {
    if (timeoutId) cancelTimeout()
    timeoutId = setTimeout(tick, duration)
  }
  const resetTimer = () => setTimer(seconds, onDone)
  const cancelTimer = () => cancelTimeout()

  const time = computed(() => countdown.value ?? 0)

  return {
    cancelTimer,
    setTimer,
    resetTimer,
    secondsRemaining: time,
  }
}
