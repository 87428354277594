import { computed, ref } from 'vue'
import { useAssetType }  from '@/components/use/useAssetType'

export const useNameNewItemFirst = (props) => {
  const { assetAlias, assetTypePlural } = useAssetType(props)
  const newName = ref('')
  const proposedName = ref('')
  const nameInputIsVisible = ref(false)
  const nameCollision = ref(null)

  const placeholder = computed(() => `New ${ props.assetType } Name`)
  const title = computed(() => props.heading ?? `Create a new <strong>${ props.assetType }</strong>`)

  const assertNameIsNotReserved = name => !props.reservedNames?.includes(name)
  const assertNameIsUnique = name => {
    nameCollision.value = props.takenNames?.includes(name)
    return !nameCollision.value
  }
  const assertNameIsValid = name => assertNameIsNotReserved(name) && assertNameIsUnique(name)
  const confirmNewName = name => {
    newName.value = name
    nameInputIsVisible.value = false
    proposedName.value = ''
  }
  const evaluateName = name => {
    if (assertNameIsValid(name)) {
      confirmNewName(name)
    } else {
      nameCollision.value = true
    }
  }
  return {
    evaluateName,
    assetAlias,
    assetTypePlural,
    nameCollision,
    newName,
    placeholder,
    nameInputIsVisible,
    title,
  }
}
