import { useStore }     from 'vuex'
import { PUT_DARKMODE }    from '../../store/graph/operations'
import { computed } from 'vue'

export function useDarkmode() {
  const store = useStore()
  const setDarkmode = value => {
    store.commit(PUT_DARKMODE, value)
  }
  const darkmode = computed(() => store.state.darkmode)
  const restoreDarkmodeFromLastSession =() => {
    const storedValue = localStorage.getItem('darkmode') ?? false
    setDarkmode(storedValue)
  }
  restoreDarkmodeFromLastSession()
  return {
    darkmode,
    setDarkmode,
  }
}
