import {computed} from "vue";
import config from '../../../../config.js'

const {
  MEDIA_REPO_ORGANIZATION,
  MEDIA_REPO_NAME
} = config

export const useMediaRepo = props => {
  const imageRepoUrl = `https://raw.githubusercontent.com/${MEDIA_REPO_ORGANIZATION}/${MEDIA_REPO_NAME}/main`
  const imagePath = computed(() => [imageRepoUrl, props.filePath].join('/'))
  return {
    imagePath,
    imageRepoUrl
  }
}
